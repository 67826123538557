<template>
  <template v-for="item in detailData.list" :key="item.id">
    <div class="detailData">
      <ul>
        <li>rateType</li>
        <li>{{ item["rateType"] }}</li>
      </ul>
      <ul>
        <li>betType</li>
        <li>{{ item["betType"] }}</li>
      </ul>
      <ul>
        <li>betTypeName</li>
        <li>{{ item["betTypeName"] }}</li>
      </ul>
      <ul>
        <li>gameType</li>
        <li>{{ item["gameType"] }}</li>
      </ul>
      <ul>
        <li>gameTypeName</li>
        <li>{{ item["gameTypeName"] }}</li>
      </ul>
      <ul>
        <li>resultType</li>
        <li>{{ item["resultType"] }}</li>
      </ul>
      <ul>
        <li>resultTypeName</li>
        <li>{{ item["resultTypeName"] }}</li>
      </ul>
      <ul>
        <li>betAmt</li>
        <li>{{ item["betAmt"] }}</li>
      </ul>
      <ul>
        <li>betWinAmt</li>
        <li>{{ item["betWinAmt"] }}</li>
      </ul>
    </div>
  </template>
</template>

<script>
export default {
  name: 'vendorCodepowerball',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
